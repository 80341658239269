/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useMemo, useState } from "react";
import firebaseApp from "@src/auth/lib/firebaseClient";
import { Auth, ParsedToken, User, getAuth } from "firebase/auth";
import {
  CourieUser,
  GetCourierQuery,
  SyncUserWithFirebaseMutation,
  useGetCourierLazyQuery,
  useSyncUserWithFirebaseMutation,
} from "@api/graphql/generated/generated-types";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import { useRouter } from "next/router";
import { useLocalStorage } from "usehooks-ts";
import { FetchResult } from "@apollo/client";
import { Loading } from "@src/common/components";

interface CustomUser extends User {
  reloadUserInfo?: any;
}

interface AuthContextInterface {
  user: User | null;
  courierId: string | null;
  setCourierId: (courierId: string | null) => void;
  isEmployee: boolean;
  authLoading: boolean;
  courieUser: CourieUser | undefined;
  isNewUser?: boolean;
  customClaims?: ParsedToken;
  courier: GetCourierQuery | undefined;
  refetchCourier: () => void;
}

export const AuthContext = createContext<AuthContextInterface>({
  user: null,
  courierId: null,
  setCourierId: () => {},
  isEmployee: false,
  authLoading: true,
  courieUser: undefined,
  isNewUser: false,
  customClaims: undefined,
  courier: undefined,
  refetchCourier: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }: Props) => {
  const { showToast } = useCourieStore();
  const [FBUser, setFBUser] = useState<User | null>(null);
  const [courieUser, setCourieUser] =
    useState<CourieUser | undefined>(undefined);
  const [isEmployee, setIsEmployee] = useState<boolean>(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [customClaims, setCustomClaims] =
    useState<ParsedToken | undefined>(undefined);

  // Storing courierId in local storage so that it persists on page refresh
  // Not necessary for user as we are using firebase auth for that
  // but needed for employee login to store in local.
  const [courierId, setCourierId] = useLocalStorage<string | null>(
    "courierId",
    null
  );
  const [authLoading, setAuthLoading] = useState<boolean>(true);
  const router = useRouter();
  const [syncUserWithFirebaseMutation] = useSyncUserWithFirebaseMutation();
  const [
    getCourier,
    {
      data: courier,
      loading: courierLoading,
      error: courierError,
      refetch: refetchCourier,
    },
  ] = useGetCourierLazyQuery();

  useEffect(() => {
    const auth: Auth = getAuth(firebaseApp);
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setAuthLoading(false);
      if (authUser) {
        const customUser = authUser as CustomUser;
        let reloadUserInfo: any | undefined = customUser.reloadUserInfo
          ? customUser.reloadUserInfo
          : undefined;
        if (reloadUserInfo && reloadUserInfo.customAttributes) {
          const customAttributes = JSON.parse(reloadUserInfo.customAttributes);
          const { isEmployee, courierId } = customAttributes;
          setIsEmployee(isEmployee);
          if (courierId) {
            setCourierId(customAttributes.courierId);
            setCourieUser({
              courierId: customAttributes.courierId || "",
              email: reloadUserInfo.email,
              firebaseUid: authUser.uid,
              id: customAttributes.courieUserId || "userid",
              profilePhotoUrl: customUser.photoURL,
            } as CourieUser);
          }
        }
        setFBUser(customUser);
      } else {
        resetAllStates();
      }
    });

    return () => unsubscribe();
  }, []);

  const resetAllStates = () => {
    setFBUser(null);
    setCourierId(null);
    setCourieUser(undefined);
  };

  useEffect(() => {
    if (!FBUser && !authLoading) {
      setCourieUser(undefined);
      router.push("/welcome/login");
      return;
    }
    if (FBUser) {
      FBUser.getIdTokenResult().then((token) => {
        const customClaims = token.claims;
        if (!customClaims.courierId || !customClaims.courieUserId) {
          syncUserWithFirebaseMutation({
            variables: {
              email: FBUser.email!,
              firebaseUid: FBUser.uid,
            },
          })
            .then((res: FetchResult<SyncUserWithFirebaseMutation>) => {
              setAuthLoading(false);
              const courieUserData = res.data;
              if (courieUserData) {
                const { user, isEmployee, error } =
                  courieUserData.syncUserWithFirebase;
                setIsEmployee(isEmployee);
                if (isEmployee && !courierId) {
                  setAuthLoading(false);
                  router.push("/admin/dashboard");
                }
                if (user && user.courierId) {
                  setCourieUser(user);
                  setCourierId(user.courierId);
                }
                if (error && !user) {
                  setCourierId(null);
                  setCourieUser(undefined);
                  setAuthLoading(false);
                  setIsNewUser(true);
                  router.push("/welcome/newUser");
                  return;
                }
              }
            })
            .catch((err) => {
              showErrorToast(err, showToast);
            });
        } else {
          setCourierId(customClaims.courierId);
          setCustomClaims(customClaims);
        }
      });
    }
  }, [FBUser]);

  useEffect(() => {
    if (courierId && FBUser) {
      getCourier({
        variables: {
          courierId: courierId,
        },
      });
    }
  }, [courierId, FBUser]);

  const contextValue = useMemo(
    () => ({
      user: FBUser,
      courierId,
      setCourierId,
      isEmployee,
      authLoading,
      courieUser,
      isNewUser,
      customClaims,
      courier,
      refetchCourier,
    }),
    [
      FBUser,
      courierId,
      isEmployee,
      authLoading,
      courieUser,
      isNewUser,
      customClaims,
      courier,
      refetchCourier,
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>
      <Loading loading={authLoading}>{children}</Loading>
    </AuthContext.Provider>
  );
};
